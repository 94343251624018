<template>
  <div>
    <md-table md-card table-header-color="blue" :value="units">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Список юнитов</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Имя">
          {{ item.name }}
        </md-table-cell>

        <md-table-cell md-label="Раса">
          {{ racesById[item.characterRaceId] }}
        </md-table-cell>

        <md-table-cell md-label="Редкость">
          {{ raresById[item.characterRareId] }}
        </md-table-cell>

        <md-table-cell md-label="Макс.уровень">
          {{ item.maxLevel }}
        </md-table-cell>

        <md-table-cell md-label="Роль">
          {{ professionsById[item.attribute.professionId] }}
        </md-table-cell>

        <md-table-cell md-label="ХП">
          {{ item.attribute.healthPoints }}
        </md-table-cell>

        <md-table-cell md-label="Атака">
          {{ item.attribute.damage }}
        </md-table-cell>

        <md-table-cell md-label="Защита">
          {{ item.attribute.defence }}
        </md-table-cell>

        <md-table-cell md-label="Скорость">
          {{ item.attribute.speed }}
        </md-table-cell>

        <md-table-cell md-label="Крит.шанс">
          {{ item.attribute.criticalChance }}
        </md-table-cell>

        <md-table-cell md-label="Инициация">
          {{ item.attribute.initiative }}
        </md-table-cell>

        <md-table-cell md-label="Дальность атаки">
          {{ item.attribute.attackRange }}
        </md-table-cell>

        <md-table-cell md-label="Коэф.умений">
          {{ item.attribute.skillQuotient }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="editUnit(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters("professions", ["professionsById"]),
    ...mapGetters("races", ["racesById"]),
    ...mapGetters("rares", ["raresById"]),
    ...mapState("units", ["units"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getProfessions();
    await this.getRaces();
    await this.getRares();
    await this.getUnits();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("professions", ["getProfessions"]),
    ...mapActions("races", ["getRaces"]),
    ...mapActions("rares", ["getRares"]),
    ...mapActions("units", ["getUnits"]),
    ...mapMutations({
      setSelectedUnit: "units/SET_SELECTED_UNIT",
      showLoader: "SET_SHOW_LOADER",
    }),

    editUnit(unit) {
      this.setSelectedUnit(unit);
      this.$router.push(`/units/${unit.characterId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
